body{
  background: linear-gradient(
     to right,pink,rgb(161, 191, 161),rgb(108, 108, 128))
    
}
.about-us {
   max-width: 1200px;
   margin: 40px auto;
   padding: 20px;
   text-align: center;
   background-color: rgb(236, 217, 217);
   border-radius: 5px;
   box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
   color:blue;
   
 }
 .list{
  list-style: none;
  text-align: center;
  
 }





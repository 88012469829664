.landing-body{
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px); 
  min-height: 800px;  
  max-height: 900px;
}

.landing-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px;
  padding: 20px; 
}
.landing-content p{
  font-size: 17px;
}
.landing-content h2{ 
  text-align: center;
  font-size: 35px;  
  padding: 20px;
  font-family: 'Tienne', serif;
}

.landing-content h2 span{ 
  color: #0913FA;
}



.three-lables{
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-lables div{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border: 5px solid black;
  height: 260px;
  width: 170px;
  margin: 0px 40px;
  padding: 10px;
  border-radius:80px / 200px;
}

.three-lables div img{
  width: 120px;
}

.three-lables div p{
  font-size: 18px;
  font-weight: 800;
}
.links {
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  color: black;  
}












.footer-body{
  background-color:rgb(162, 159, 159);
  height: 500px;
  width: 100%;
  color:black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.footer-section {
  margin: 20px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-size: 30px;  
}
.footer-callus{
  margin-top: 0;
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-size: 30px;

}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-icons{
  text-align: center;
  margin-top: 220px;
  color: #2126cd;
}
.copyright{
  font-weight: bold;
}
.para{
  color: black;
  font-size: 20px;
 text-align: center;
}
.p_li{
  color: black;
  font-size: 20px;
  margin: 0;
}
nav{
  display: flex;
  margin-left: 20px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.title{
  font-size: 1.5rem;
  margin: 1rem;
  font-size: 30px;
  margin-left: 30px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
nav ul{
  display: flex;
  margin-top: 20px;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  padding: 1px;
  padding-bottom: 0;
}
nav ul a{
  text-decoration: none;
  color: black;
}
nav ul li{
  list-style: none;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  align-items: center;
  text-align: center;
  justify-content: center;
}
nav .menu{
  display: none;
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  flex-direction: column;
  width: 2.25rem;
  height: 2rem;
  cursor: pointer;
}
nav .menu span{
  height: 0.3rem;
  width: 100%;
  background-color: black;
  border-radius: 0.2rem;
  padding: 4px;
  margin: 0.1rem;
}

.three-lables img:hover{
  filter: grayscale(1);
  transform: scale(1.3) rotate(7deg);
  cursor: pointer;
}
@media (max-width:480px){
  .landing-body{
    width: 100%;
    position: absolute;
    top: 60px;
    max-height: 1500px;
  
  }
  .title{
    font-size: 1.5rem;
    font-size: 30px;
    margin-left:70px;
    margin-top: 10px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  nav .menu{
    display: flex;
    margin-top: 10px;
    margin-right: 20px;
  }
  nav{
    flex-direction: column;
    align-items: start;
    margin-top: 0px;
  }
  nav ul{
    display: none;
    flex-direction: column;
    width: 50%;
  }
  nav ul.open{
    display: flex;
    margin-left: 200px;
  }
  nav ul li{
    width: 50%;
    text-align: end;
    padding: 0.2rem;
    font-size: 10px;
    margin-right: 60px;
    margin-top: 0px;
  }
  .three-lables{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-right: 80px;
  }
  .three-lables .links{
    width: 170px;
  
  }
  .three-lables div img{
    width: 170px;

  }
  .three-lables div{
    width: 250px;
    height: 300px;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
    margin-bottom: 0.9rem;
    margin-top: 10px;
    justify-content: center;
  }
  .three-lables div p{
    font-size: 25px;
  }
  .landing-body{
    height: 700px;
    width: 100%;
  }

.landing-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px;
  padding: 20px; 
}
.landing-content p{
  font-size: 17px;
}
.landing-content h2{ 
  text-align: center;
  font-size: 10px;  
  padding: 20px;
  font-family: 'Tienne', serif;
  margin-left: 5px;
  margin-right: 5px;
  margin-top:0;
}

.landing-content h2 span{ 
  color: #0913FA;
}
.footer-icons{
  text-align: center;
  margin-top: 50px;
  color: #2126cd;
}
.copyright{
  margin-bottom: 0.1rem;
  font-size: small;
  font-weight: bold;
  background-color: rgb(169, 168, 168);

}
.footer-callus{
  margin-top: 13px;
}
.user{
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 280px;
  margin-top: 10px;
  margin-left: 2px;
}

}
@media (max-width:768px) and (min-width:481px){
 
  .title{
    font-size: 20px;
    margin-left: 5px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
   margin-right: 80px;
  }
  nav{
    flex-direction: row;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-right: 100px;
    margin-left: 0;
  }
  nav ul{
    display: flex;
    flex-direction: row;


  }
  nav ul li{
    text-align: center;
    font-size: 12px;
  }
  .three-lables .links{
    width: 150px;
  }
  .three-lables div img{
    width: 80px;
  }
  .three-lables div{
    width: 100px;
    height: 150px;
    margin-left: 7px;
    margin-right: 5px;
    padding: 2px;
  
  }
  .three-lables div p{
    font-size: 12px;
  }
 
  .landing-body{
    width: 100%;
    position: absolute;
    top: 60px;
    height: calc(100% - 60px);
    min-height: 600px;
    max-height: 900px;
  }
  .landing-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px;
    padding: 20px; 
  }
  .landing-content p{
    font-size: 17px;
  }
  .landing-content h2{ 
    text-align: center;
    font-size: 35px;  
    padding: 20px;
    font-family: 'Tienne', serif;
  }
  
  .landing-content h2 span{ 
    color: #0913FA;
  }
  .user{
    cursor: pointer;
    display: inline-block;
    margin-bottom: 3px;
    position: absolute;
    margin-left: 380px;
    margin-top: 10px;
    size: 25px;
  }
}
@media (max-width:1020px) and (min-width:769px){
  nav{
    display: flex;
    position: sticky;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5px;
  }
  .landing-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px;
    padding: 20px; 
  } 
  .landing-content p{
    font-size: 17px;
  }
  .landing-content h2{ 
    text-align: center;
    font-size: 35px;  
    padding: 20px;
    font-family: 'Tienne', serif;
  }
  .landing-content h2 span{ 
    color: #0913FA;
  }
  .title{
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 70px;
    font-family: Arial, Helvetica, sans-serif;
  
  }
  nav ul{
    display: flex;
    margin-top: 10px;
    text-decoration: none;
    text-align: center;
    margin-left: 5px;
    text-align: center;
  
  
  }
  nav ul a{
    text-decoration: none;
    color: black;
  }
  nav ul li{
    list-style: none;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: bold;
    margin-left: 25px;
    margin-bottom: 40px;
    text-align: center;
  }
    
.landing-body{
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(100% - 60px);
  min-height: 600px;
  max-height: 900px;
}
.three-lables{
  display: flex;
  justify-content: center;
  align-items: center;
}

.three-lables div{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 240px;
  width: 140px;
  margin-left: 10px;
  border: 5px solid black;
  border-radius: 80px / 200px;
  padding: 2px;
}

.three-lables div img{
  width: 110px;
}

.three-lables div p{
  font-size: 18px;
  font-weight: 800;
  
  
}
.links {
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  color: black;  
} 
.user{
  cursor: pointer;
  display: inline-block;
  position: absolute;
  margin-left: 550px;
  
  
}
}
@media (max-width:1300px) and (min-width:1021px){
  .landing-body{
    width: 100%;
    position: absolute;
    top: 60px;
    height: calc(100% - 60px);
    min-height: 600px;
    max-height: 900px;
  }
  .navbar{
    background-color: rgb(134, 169, 169);
    
  }
  .title{
    font-size: 1.5rem;
    margin: 1rem;
    font-size: 30px;
    margin-left: 10px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  nav ul{
    display: flex;
    margin-top: 10px;
    text-decoration: none;
    text-align: center;
    margin-left: 170px;
  
  
  }
  nav ul a{
    text-decoration: none;
    color: black;
    
    
  }
  nav ul li{
    list-style: none;
    padding: 0.5rem;
    cursor: pointer;
    font-weight: bold;
    margin-left: 10px;
    text-align: center;  
  
  }
.signup-btn{
  width: 50px;

}
.user{
  cursor: pointer;
  display: inline-block;
  position: static;
  margin-left: 380px;
  text-align: center;
  margin-top: 10px;
  
}


}









 
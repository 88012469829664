.h1{
   text-align: center;
   color: blue;
   font-weight: bold;
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
.img{
   height: 200px;
   width: 200px;
   cursor: pointer;
   
   }
   .picture{
      display: flex;
      margin: 20px;
      flex-flow:row;
      flex-wrap: wrap;
       
   }
   .flex-one{
      display: flex;
      flex-direction: column;
      margin: 20px;
      box-sizing: border-box;
           
   }
 .button-products{
   background-color: rgb(241, 70, 22);
   text-align: center;
   
 }
 .btn{
   color: white;
   width: auto;
 }
 .label-name{
   text-align: center;
   color: black;
   font-size: 20px;
   background-color:white;

 }


      
      
      

   
 

